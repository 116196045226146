import React from "react";
import { Link } from "react-router-dom";
import landing from "../images/landing.jpg";

const Landing = () => {
  return (
    <div className="land">
      <img
        src={landing}
        alt="logo"
        className="logo"
        style={{ width: "100%", height: "100%" }}
      />

      <div className="content-landing">
        <h1 className="fw-bold">اختبار الحالة النفسيه</h1>
        <p className="fs-">
          هو اختبار مكون من عدة اسئلة مبنية علي اسس علمية تستهدف نقاط هامة في
          شخصية متلقي الاختبار يتم معالجت اجابتاك عليه باستخدام الذكاء الاصطناعي
          ، يساعدك هذا الاختبار في الاطمئنان ما اذ كنت تعاني من اي اضطرابات
          نفسية او امراض شخصية او شخص طبيعي و يقدم صورة متكاملة عن جميع الجوانب
          الهامة والمتعددة في الشخصية توفر علي الاطباء النفسيين تشخيص مبدئي
          لحالة المريض او تئكيد علي تشخيصاتهم
        </p>
        <div>
          <Link to="/Validation">
            <button className="btn btn-primary my-2 px-5 btn-style mx-3">
              ابدأ
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Landing;
