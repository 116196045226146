import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { answer } from "../Answer";
import { Spinner } from "react-bootstrap";

const FinalResults = () => {
  const [result, setResult] = useState(null);

  const sendDataToAPI = () => {
    // const url = "http://127.0.0.1:8000/modelAi/SendData";
    const url = "https://153.92.209.30:6020/modelAi/SendData";
    const data = { myList: [...answer] };

    axios
      .post(url, data)
      .then((res) => {
        setResult(res.data.message);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    sendDataToAPI();
    console.log(answer);
  }, []);

  const dic = {
    "social introversion": "انت مريض بالانطواء الاجتماعي",
    imagination: "انت مريض بالتوهم",
    paranoia: "انت مريض بالبارانويا",
    Schizophrenia: "انت مريض بالفصام",
    obsession: "انت مريض بالهوس",
    narcissism: "انت مريض بالنرجسية",
    phopia: "انت مريض بالفوبيا",
    hysteria: "انت مريض بالهستيريا",
    "Normal Person": "انت شخص طبيعي",
  };

  return (
    <div className="form-input">
      {result ? (
        <h1>{dic[result.toString().trim()]}</h1>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner
            animation="grow"
            variant="dark"
            style={{ backgroundColor: "#011339" }}
          />
          <span style={{ color: "#011339" }} className="fs-4 mx-3">
            Loading

          </span>
        </div>
      )}
      <a href="/">
        <button className="btn btn-primary my-5 px-5 btn-style">
          الصفحة الرئيسيه
        </button>
      </a>
    </div>
  );
};

export default FinalResults;
