const data = [
  { id: " 1	", q: "	شهيتي للطعام جيدة.	 " },
  { id: "	2	", q: "	أستيقظ في معظم الأيام نشطاً ومرتاحاً.	 " },
  { id: "	3	", q: "	أحب أن أقرأ المقالات المتعلقة بالجرائم.	 " },
  { id: "	4	", q: "	یدای وقدماي دافئة في العادة.	 " },
  { id: "	5	", q: "	حياتي اليومية مليئة بما يثير اهتمامي.	 " },
  { id: "	6	", q: "	قدرتي على العمل هي هي لم تتغير عما كانت عليه من قبل.	 " },
  { id: "	7	", q: "	أشعر كأن شيئاً يقف في حلقي (أشعر بغصة) معظم الوقت.	 " },
  { id: "	8	", q: "	يجب أن يحاول الشخص أن يفهم أحلامه وأن يتخذ منها دليلاً يرشده أو نذيراً يحذره.	 " },
  { id: "	9	", q: "	أستمتع بقراءة الروايات البوليسية.	 " },
  { id: "	10	", q: "	عندما أؤدي عملي أكون في حالة توتر شديد.	 " },
  { id: "	11	", q: "	أفكر من حين لآخر في أشياء هي من القبح بحيث لا يمكن التحدث عنها.	 " },
  { id: "	12	", q: "	لا شك في أنني مظلوم في هذه الحياة.	 " },
  { id: "	13	", q: "	والدي رجل طيب.	 " },
  { id: "	14	", q: "	قليلاً ما أصاب بالإمساك.	 " },
  { id: "	15	", q: "	تعاودني رغبه شديدة أحياناً في أن أترك أسرتي وأبتعد عنها.	 " },
  { id: "	16	", q: "	تنتابني احياناً نوبات من الضحك والبكاء لا أستطيع مقاومتها.	 " },
  { id: "	17	", q: "	أصاب أحياناً بنوبات من الغثيان والقيء.	 " },
  { id: "	18	", q: "	يبدو أنه لا يوجد من يفهمني.	 " },
  { id: "	19	", q: "	أود أن أكون مغنياً.	 " },
  { id: "	20	", q: "	أشعر - عندما أكون في مأزق أن من الافضل لي ألا اتكلم.	 " },
  { id: "	21	", q: "	تسيطر على روح الشر في بعض الأحيان.	 " },
  { id: "	22	", q: "	أصاب بحموضة المعدة بشكل يضايقني عدة أيام في الأسبوع.	 " },
  { id: "	23	", q: "	أشعر أحياناً برغبة في السب.	 " },
  { id: "	24	", q: "	أجد صعوبة في أن أركز ذهني في عمل أو مهمة.	 " },
  { id: "	25	", q: "	مررت بخبرات في منتهى العجب والغرابة.	 " },
  { id: "	26	", q: "	لو لم يضمر الناس العداوة لي لكنت أكثر نجاحاً بكثير مما أنا عليه.	 " },
  { id: "	27	", q: "	قليلاً ما ينتابني القلق على صحتي.	 " },
  { id: "	28	", q: "	أفضل في جميع الأوقات أن أجلس وأسترسل في أحلام اليقظة على أن أقوم بأي عمل آخر.	 " },
  { id: "	29	", q: "	مرت بي فترات تقدر بالأيام وأحياناً بالأسابيع أو الأشهر فقدت فيها القدرة على الاهتمام بما حولي وذلك لأنني لم أستطع مواصلة نشاطي.	 " },
  { id: "	30	", q: "	نومی مضطرب وقلق.	 " },
  { id: "	31	", q: "	أشعر في معظم الأوقات بآلام في جميع أجزاء رأسي.	 " },
  { id: "	32	", q: "	أشعر كل أسبوع - أو أقل - بسخونة تعم جسمي فجأة وذلك دون ما سبب ظاهر.	 " },
  { id: "	33	", q: "	صحتي الجسمية كصحة معظم أصدقائي في الجودة.	 " },
  { id: "	34	", q: "	أفضل أن أتجاهل أصدقاء المدرسة أو المعارف الذين لم أرهم منذ مدة طويلة، إلا إذا بادروني هم بالكلام.	 " },
  { id: "	35	", q: "	لا تكاد تنتابني أية آلام في القلب أو في الصدر.	 " },
  { id: "	36	", q: "	إنني سهل الاختلاط بالناس.	 " },
  { id: "	37	", q: "	غالباً ما اضطررت إلى أن أتلقى أوامر ممن هو أقل منى معرفة.	 " },
  { id: "	38	", q: "	كثيراً ما أشعر في بعض أجزاء جسمي بما يشبه الاحتراق أو القشعريرة أو التنميل أو التخدير.	 " },
  { id: "	39	", q: "	لم يحدث أن وجدت أية صعوبة في ضبط عملية التبرز.	 " },
  { id: "	40	", q: "	أثابر أحياناً على القيام بعمل ما إلى أن يفقد الآخرون صبرهم معي.	 " },
  { id: "	41	", q: "	أحب والدي.	 " },
  { id: "	42	", q: "	أتمنى لو كنت سعيداً كما يبدو الآخرون.	 " },
  { id: "	43	", q: "	لم يحدث لي تقريباً أن شعرت بألم في خلف العنق.	 " },
  { id: "	44	", q: "	أعتقد أن كثيراً من الناس يبالغون في تصوير سوء حظهم كي ينالوا عطف الآخرين ومساعدتهم.	 " },
  { id: "	45	", q: "	تنتابني آلام في المعدة كل بضعة أيام قلائل.	 " },
  { id: "	46	", q: "	أعتقد أنني شخص مهم.	 " },
  { id: "	47	", q: "	أشعر بالحزن والانقباض في معظم الأوقات.	 " },
  { id: "	48	", q: "	ليس من السهل أن يجرح أحد شعوري.	 " },
  { id: "	49	", q: "	من السهل أن أهزم في المناقشة.	 " },
  { id: "	50	", q: "	من المؤكد أنني قليل الثقة بنفسي.	 " },
  { id: "	51	", q: "	إقناع الناس بالصواب يتطلب مجهوداً كبيراً.	 " },
  { id: "	52	", q: "	 لا يضيرني أن أكون موضوع مزاح.	 " },
  { id: "	53	", q: "	أعتقد أن معظم الناس مستعدون لأن يكذبوا في سبيل التفوق على غيرهم.	 " },
  { id: "	54	", q: "	أعمل أشياء كثيرة أندم عليها فيما بعد.	 " },
  { id: "	55	", q: "	تنتابني أحياناً رغبة جامحة في القيام بعمل يضر الآخرين أو يصدمهم.	 " },
  { id: "	56	", q: "	أحب أن أذهب إلى الحفلات أو الاجتماعات الأخرى التي أجد فيها الكثير من اللهو والصخب.	 " },
  { id: "	57	", q: "	قابلت مشكلات لم أستطع أن أقرر شيئاً بشأنها لكثرة ما كان لها من حلول.	 " },
  { id: "	58	", q: "	أعتقد أنه يجب أن يكون للمرأة من الحرية الجنسية مثل ما للرجل.	 " },
  { id: "	59	", q: "	أشد المعارك عندي هي المعركة بيني وبين نفسي.	 " },
  { id: "	60	", q: "	لا أشكو تقلصاً في العضلات وإن فعلت فنادراً.	 " },
  { id: "	61	", q: "	يبدو إنني لا أهتم بما يحدث لي.	 " },
  { id: "	62	", q: "	يضايقني أحياناً أن تسوء صحتي.	 " },
  { id: "	63	", q: "	أشعر في معظم الأحيان أنني قد ارتكبت خطأ أو أتبت شراً.	 " },
  { id: "	64	", q: "	أشعر بالسعادة في معظم الأوقات.	 " },
  { id: "	65	", q: "	أشعر معظم الوقت بأن رأسي على وشك الانفجار.	 " },
  { id: "	66	", q: "	بعض الناس يصل حبهم للسيطرة إلى درجة أشعر معها برغبة في مخالفتهم حتى ولو كان على حق.	 " },
  { id: "	67	", q: "	بعض الناس يضمر لي في نفسه شيئاً.	 " },
  { id: "	68	", q: "	لم أقم قط يعمل فيه خطر لمجرد الإثارة التي تترتب على ذلك.	 " },
  { id: "	69	", q: "	أشعر غالباً بضغط شديد حول رأسي.	 " },
  { id: "	70	", q: "	أعتقد أن الناس أمناء لخوفهم أن يكتشف أمرهم.	 " },
  { id: "	71	", q: "	لم يتغير صوتي عما كان عليه فلم بعد أسرع أو أبطأ أو أكثر حشرجة أو أحسن من ذي قبل).	 " },
  { id: "	72	", q: "	لا أهتم بمراعاة آداب المائدة في منزلي كما أهتم بمراعاتها خارج المنزل.	 " },
  { id: "	73	", q: "	أعتقد أن هناك مؤامرة تدير ضدي.	 " },
  { id: "	74	", q: "	يبدو إنني لا أقل نباهة أو قدرة عن معظم الناس المحيطين بي.	 " },
  { id: "	75	", q: "	أعتقد أن هناك من يتتبعني.	 " },
  { id: "	76	", q: "	معظم الناس يستخدمون وسائل ملتوية للحصول على كسب أو منفعة بدلاً من أن يتركوا الفرصة تضيع عليهم.	 " },
  { id: "	77	", q: "	كثيراً ما تتعبني معدتي.	 " },
  { id: "	78	", q: "	أحب الروايات العاطفية العنيفة.	 " },
  { id: "	79	", q: "	أننى أعرف من هو المسئول عن معظم متاعبي.	 " },
  { id: "	80	", q: "	رؤية الدم لا تسبب لي الرعب.	 " },
  { id: "	81	", q: "	لا أستطيع أن أرى في أغلب الأحيان سبباً لغضبي أو نقمتي على الحياة.	 " },
  { id: "	82	", q: "	لم يحدث أن تقيأت دماً أو سعلت دماً.	 " },
  { id: "	83	", q: "	لم يحدث أن انغمست في عادة من العادات الجنسية الشاذة.	 " },
  { id: "	84	", q: "	تتوارد الأفكار في ذهني أحياناً بأسرع مما أستطع أن أعبر به.	 " },
  { id: "	85	", q: "	غالباً ما أتساءل عن الأسباب المستترة التي تدفع الآخرين إلى معونتي.	 " },
  { id: "	86	", q: "	أعتقد أن حياتي المنزلية تعادل من حيث السعادة حياة معظم الناس الآخرين الذين أعرفهم.	 " },
  { id: "	87	", q: "	النقد أو اللوم يجرحان شعوري إلى حد كبير.	 " },
  { id: "	88	", q: "	تحدد سلوكي إلى حد كبير التقاليد المحيطة بي.	 " },
  { id: "	89	", q: "	أشعر في بعض الأحيان شعوراً قوياً أنني عديم الفائدة.	 " },
  { id: "	90	", q: "	كنت انتمى في طفولتي إلى جماعة (شلة) اتفقت على أن تتحد في السراء والضراء.	 " },
  { id: "	91	", q: "	فقدت كثيراً من الفرص لأني لم أستطع أن أبت في الأمور بصورة قاطعة.	 " },
  { id: "	92	", q: "	إنني أفقد صبري إذا ما قاطعني الناس أثناء اشتغالي بأمر هام.	 " },
  { id: "	93	", q: "	أعتقد أن بعض الناس يحاول أن يدس السم لي.	 " },
  { id: "	94	", q: "	أنام في معظم الليالي دون أن تضايقني الأفكار.	 " },
  { id: "	95	", q: "	كانت صحتي في خلال السنوات القليلة الماضية جيدة على وجه العموم.	 " },
  { id: "	96	", q: "	وزني لا يزيد ولا ينقص.	 " },
  { id: "	97	", q: "	مرت بی فترات كنت أقوم فيها بأفعال دون أن أعرف بعد ذلك ماذا كنت أفعل.	 " },
  { id: "	98	", q: "	أشعر بأنني كثيراً ما عوقبت دون سبب.	 " },
  { id: "	99	", q: "	أبكى بسهولة.	 " },
  { id: "	100	", q: "	لم أعد أفهم ما أقرأ بنفس الدرجة التي كنت أفهم بها سابقاً.	 " },
  { id: "	101	", q: "	لم أشعر في يوم من الأيام بأنني أحسن مما أنا عليه الآن.	 " },
  { id: "	102	", q: "	أشعر أحياناً أن قمة رأسى رخوة (طرية).	 " },
  { id: "	103	", q: "	لا أقبل أن يستغلني أحد إلى الحد الذي لا أجد معه مفراً من أن أعترف بذلك.	 " },
  { id: "	104	", q: "	لا أتعب بسرعة.	 " },
  { id: "	105	", q: "	أحب أن أدرس الأشياء التي أشتغل بها وأن أقرأ عنها.	 " },
  { id: "	106	", q: "	أخاف عندما أطل من مكان عال.	 " },
  { id: "	107	", q: "	لا يثيرني أن يقع أحد أفراد عائلتي في مأزق بسبب مخالفته للقانون.	 " },
  { id: "	108	", q: "	أشعر أن عقلى مختل.	 " },
  { id: "	109	", q: "	 لا يهمني ما يظنه الناس عنى.	 " },
  { id: "	110	", q: "	يضايقني أن أقوم بدور المهرج في حفلة حتى ولو كان الاخرون يقومون بنفس الشي.	 " },
  { id: "	111	", q: "	كثيراً ما أضطر إلى أن أبذل مجهوداً كبيراً لأخفى ما قد يظهر من خجلي.	 " },
  { id: "	112	", q: "	لم يحدث لي قط أن أغمى على.	 " },
  { id: "	113	", q: "	قلما حدث أن أصبت بدوخة.	 " },
  { id: "	114	", q: "	أمى سيدة طيبة.	 " },
  { id: "	115	", q: "	يبدو أن ذاكرتي لا بأس بها.	 " },
  { id: "	116	", q: "	الأمور الجنسية تثير عندى القلق.	 " },
  { id: "	117	", q: "	أجد صعوبة في التحدث مع الناس إذا كانت معرفتي بهم حديثة.	 " },
  { id: "	118	", q: "	أشعر برغبة في القيام بعمل مثير حين ينتابني السأم أو الملل.	 " },
  { id: "	119	", q: "	أخشى أن يطير صوابي.	 " },
  { id: "	120	", q: "	غالباً ما ألاحظ أن يداي ترتجفان عندما أحاول أن أقوم بعمل ما.	 " },
  { id: "	121	", q: "	لم يحدث أن فقدت يداي إتزانهما أو مهارتهما.	 " },
  { id: "	122	", q: "	أستطيع القراءة مدة طويلة دون أن تتعب عینای.	 " },
  { id: "	123	", q: "	أشعر في معظم بضعف عام.	 " },
  { id: "	124	", q: "	قليلاً ما أصاب بالصداع.	 " },
  { id: "	125	", q: "	لم يحدث أن وجدت صعوبة في حفظ توازني في المشي.	 " },
  { id: "	126	", q: "	لا أشكو من نوبات الربو أو نوبات الزكام.	 " },
  { id: "	127	", q: "	تحدث لي نوبات لا أستطيع فيها أن أسيطر على حركاتي أو على كلامي وإن كنت أشعر بما يدور حولى.	 " },
  { id: "	128	", q: "	أحب أن أزور الأماكن التي لم تسبق لي رؤيتها.	 " },
  { id: "	129	", q: "	قليلاً ما أستغرق في أحلام اليقظة.	 " },
  { id: "	130	", q: "	أتمنى لو لم أكن خجولاً إلى هذا الحد.	 " },
  { id: "	131	", q: "	أعتقد أنني شخص منحوس.	 " },
  { id: "	132	", q: "	أحب أن أغازل الجنس الآخر.	 " },
  { id: "	133	", q: "	كل شئ في نظري سواء.	 " },
  { id: "	134	", q: "	أهلى يعاملوني كما يعامل الطفل لا كما يعامل الراشد	 " },
  { id: "	135	", q: "	أتحاشى أثناء المشى على رصيف الشارع مثلاً أن تسقط قدمى في الشقوق الموجودة بين كل حجر وآخر.	 " },
  { id: "	136	", q: "	لم يحدث أن أصبت بطفح جلدي سبب لى قلقاً.	 " },
  { id: "	137	", q: "	غالباً ما أجد نفسي قلقاً على أمر من الأمور.	 " },
  { id: "	138	", q: "	أحب والدتي.	 " },
  { id: "	139	", q: "	لا أجد صعوبة في طلب المعونة من أصدقائي حتى ولو كنت لا أستطيع أن أرد الجميل.	 " },
  { id: "	140	", q: "	بعض أفراد أسرتي لهم عادات تضايقني مضايقة شديدة.	 " },
  { id: "	141	", q: "	أشعر أحياناً أنني أستطيع أن أتخذ القرارات بسهولة غير عادية.	 " },
  { id: "	142	", q: "	أود الانتماء إلى أندية وهيئات عديدة.	 " },
  { id: "	143	", q: "	لم يحدث لي قط أن شعرت بشدة خفقان قلبي أو أصبت بضيق في التنفس.	 " },
  { id: "	144	", q: "	أحب التحدث في الأمور الجنسية.	 " },
  { id: "	145	", q: "	الهمت خطة في الحياة تقوم على اساس اداء الواجب، ولا ازال اتبعها بدقة.	 " },
  { id: "	146	", q: "	أحياناً ما اعترضت طريق بعض الناس ومنعتهم من القيام بأعمال معينة لا لشئ إلا المبدأ.	 " },
  { id: "	147	", q: "	أغضب بسهولة ولكن سرعان ما أعود إلي حالتي الطبيعية.	 " },
  { id: "	148	", q: "	كثيراً ما استغرق في التفكير.	 " },
  { id: "	149	", q: "	تمر بي فترات من عدم الاستقرار بحيث لا استطيع ان امكث طويلاً في مقعدي.	 " },
  { id: "	150	", q: "	فشلت في الحب.	 " },
  { id: "	151	", q: "	لا اهتم مطلقاً بمظهرى.	 " },
  { id: "	152	", q: "	احلم عادة بأشياء أفضل أن احتفظ بها لنفسى.	 " },
  { id: "	153	", q: "	لا اشكو من آلام إلا قليلاً جداً لدرجة العدم.	 " },
  { id: "	154	", q: "	لا الوم أى شخص يحاول أن يحصل لنفسه على ما يمكن أن تقع عليه يداه في هذا العالم.	 " },
  { id: "	155	", q: "	تصيبني نوبات يتوقف فيها نشاطى ولا أشعر فيها بما يدور حولي.	 " },
  { id: "	156	", q: "	أستطيع أن أبقى صلتي بأولئك الذين تصدر منهم أفعال أعتبرها خطاً.	 " },
  { id: "	157	", q: "	أحب أن أكون مع مجموعة من الأشخاص يتبادل أفرادها النكات.	 " },
  { id: "	158	", q: "	أجد أن من الصعب على أن أبدأ عملاً من الأعمال.	 " },
  { id: "	159	", q: "	لا يضايقني أنني لست أحسن شكلاً مما أنا عليه.	 " },
  { id: "	160	", q: "	أعرق بسهولة حتى في الجو البارد.	 " },
  { id: "	161	", q: "	أنا واثق كل الثقة في نفسى.	 " },
  { id: "	162	", q: "	من الأسلم الا يثق الانسان بأحد.	 " },
  { id: "	163	", q: "	يهتاج شعورى مرة أو أكثر في الأسبوع.	 " },
  { id: "	164	", q: "	أجد صعوبة في التفكير في موضوعات مناسبة للحديث، عندما أكون في وسط مجموعة من الناس.	 " },
  { id: "	165	", q: "	غالباً ما ينقذني شئ مثير من حالات الانقباض التي أقع فيها.	 " },
  { id: "	166	", q: "	إنني لا ألوم أى شخص يستغل من يعرض نفسه للإستغلال.	 " },
  { id: "	167	", q: "	أفقد الإحساس في منطقة أو أكثر من جلدی.	 " },
  { id: "	168	", q: "	لم يضعف بصرى عما كان عليه من عدة سنوات.	 " },
  { id: "	169	", q: "	أشعر أن شخصاً ما يسيطر على عقلى.	 " },
  { id: "	170	", q: "	أجد متعة كبيرة مع الأطفال.	 " },
  { id: "	171	", q: "	أعجب أحياناً بمهارة بعض النصابين لدرجة أنني أرجو لهم النجاح فيما يقومون به.	 " },
  { id: "	172	", q: "	غالباً ما أشعر أن بعض الغرباء ينظرون إلى بعين النقد.	 " },
  { id: "	173	", q: "	أشرب من الماء يومياً مقادير كبيرة بشكل غير عادي.	 " },
  { id: "	174	", q: "	لا أشعر غالياً بطنين أو رنين في أذني.	 " },
  { id: "	175	", q: "	أشعر من حين لآخر بكراهية نحو أفراد أسرتي التي أحبها في العادة.	 " },
  { id: "	176	", q: "	إنني واثق من أن الناس يتكلمون عني.	 " },
  { id: "	177	", q: "	یزداد اشمئزازي من القانون كلما وجدت بعض المجرمين قد نجا من العقاب بفضل مهارة محام قدير.	 " },
  { id: "	178	", q: "	حدث أحياناً أن شعرت بأن شخصاً ما يدفعني إلى القيام بأعمال عن طريق تنويمي مغناطيسياً.	 " },
  { id: "	179	", q: "	لا أبدأ الناس عادة بالحديث ما لم يبدأوني هم.	 " },
  { id: "	180	", q: "	أشعر أن شخصا ما يحاول ان يؤثر على عقلي.	 " },
  { id: "	181	", q: "	لم يحدث قط أن أصطدمت بالقانون.	 " },
  { id: "	182	", q: "	تمر على فترات أشعر فيها بانشراح دون ما سبب ظاهر.	 " },
  { id: "	183	", q: "	أتمنى ألا تضايقني الأفكار الجنسية.	 " },
  { id: "	184	", q: "	إذا وجدت مجموعة من الناس أنها في مأزق فالأفضل أن يتفقوا على قصة واحدة ويلتزموها جميعاً.	 " },
  { id: "	185	", q: "	أعتقد أنني أعمق شعوراً من معظم الناس.	 " },
  { id: "	186	", q: "	الحياة صعبة لي في معظم الأحيان.	 " },
  { id: "	187	", q: "	لم يحدث أن وقعت في مأزق لأسباب تتعلق بالسلوك الجنسي.	 " },
  { id: "	188	", q: "	إنني شديد الحساسية بالنسبة لبعض الموضوعات لدرجة لا أستطيع التحدث فيها.	 " },
  { id: "	189	", q: "	كنت أجد وأنا في المدرسة أن من الصعب على أن أتحدث أمام الفصل.	 " },
  { id: "	190	", q: "	أشعر في معظم الأيام بالوحدة حتى ولو كنت مع آخرين.	 " },
  { id: "	191	", q: "	إنني حاصل على كل ما أسـتـحـقـه من العطف.	 " },
  { id: "	192	", q: "	أرفض الإشتراك في بعض الألعاب التي لا أجيدها.	 " },
  { id: "	193	", q: "	أرغب بشدة أحياناً في مغادرة المنزل.	 " },
  { id: "	194	", q: "	أشعر بأننى أكون صداقاتي بنفس السرعة التي يكون بها الآخرون صداقاتهم.	 " },
  { id: "	195	", q: "	إننى راض عن حياتي الجنسية بوجه عام.	 " },
  { id: "	196	", q: "	أرتكبت بعض السرقات البسيطة عندما كنت طفلاً صغيراً.	 " },
  { id: "	197	", q: "	أكره وجود الناس حولى.	 " },
  { id: "	198	", q: "	أعتقد أن الشخص الذي يترك أشياء ثمينة عرضة للسرقة يستحق من اللوم بقدر ما يستحقه اللص الذي يسرقها .	 " },
  { id: "	199	", q: "	أفكر من حين لآخر في أشياء من القبح بحيث لا يصح  التحدث عنها .	 " },
  { id: "	200	", q: "	أنا واثق من إننى مظلوم في هذه الحياة.	 " },
  { id: "	201	", q: "	أعتقد أن كل شخص تقريباً قد يكذب ليتجنب الوقوع في المآزق.	 " },
  { id: "	202	", q: "	إنني أكثر حساسية من معظم الناس.	 " },
  { id: "	203	", q: "	حياتي اليومية مملوءة بما يثير اهتمامي.	 " },
  { id: "	204	", q: "	معظم الناس يكره في قرارة نفسه أن يساعد الآخرين.	 " },
  { id: "	205	", q: "	كثير من أحلامي يتعلق بالأمور الجنسية.	 " },
  { id: "	206	", q: "	من السهل أن أحرج.	 " },
  { id: "	207	", q: "	المال والعمل يثيران القلق عندى.	 " },
  { id: "	208	", q: "	مررت بخبرات غاية في الغرابة.	 " },
  { id: "	209	", q: "	لم أقع في حب أي شخص.	 " },
  { id: "	210	", q: "	إن ما قام به بعض أفراد أسرتي أثار في الفزع.	 " },
  { id: "	211	", q: "	تعتريني من حين لآخر نوبات من الضحك والبكاء لا استطيع السيطرة عليها.	 " },
  { id: "	212	", q: "	كان أبي أو أمى يرغمنى على الطاعة حتى ولو كنت أعلم أن الأمر الذي أطيعه غير معقول.	 " },
  { id: "	213	", q: "	أجد صعوبة في أن أركز ذهني في عمل أو مهمة ما.	 " },
  { id: "	214	", q: "	أحياناً ما أفقد قدرتي على الكلام أو يتغير صوتي حتى ولو لم أكن مصاباً ببرد.	 " },
  { id: "	215	", q: "	لو لم يضمر لى الناس العداوة لكنت أكثر نجاحاً بكثير مما أنا عليه الآن.	 " },
  { id: "	216	", q: "	أحياناً ما يحتبس صوتي أو يتغير حتى لو لم أكن مصاباً بالبرد.	 " },
  { id: "	217	", q: "	پیدو أنه لا يوجد من يفهمني.	 " },
  { id: "	218	", q: "	أشم أحياناً روائح غريبة.	 " },
  { id: "	219	", q: "	لا أستطيع أن أركز فكرى في موضوع واحد.	 " },
  { id: "	220	", q: "	من السهل أن ينفذ صبرى مع الناس.	 " },
  { id: "	221	", q: "	غالباً ما أشعر بالقلق على شئ أو شخص ما.	 " },
  { id: "	222	", q: "	لا شك في أن نصيبي من الأشياء المسببة للقلق في هذه الحياة كان كبيراً.	 " },
  { id: "	223	", q: "	أتمنى الموت في معظم الأحيان.	 " },
  { id: "	224	", q: "	يتهيج شعوري في بعض الأحيان لدرجة أنه يتعذر على النوم.	 " },
  { id: "	225	", q: "	يصبح سمعي أحياناً مرهقاً لدرجة تضايقنی.	 " },
  { id: "	226	", q: "	أنسى ما يقال لي في الحال.	 " },
  { id: "	227	", q: "	غالباً ما أتوقف وأفكر قبل أن أعمل حتى في الأمور التافهة.	 " },
  { id: "	228	", q: "	غالباً ما أعبر الطريق لاتحاشى مقابلة شخص ما.	 " },
  { id: "	229	", q: "	أشعر أحياناً أن الأشياء ليست حقيقة واقعة.	 " },
  { id: "	230	", q: "	عندى عادة عد الأشياء غير الهامة كلمبات الكهرباء في الطريق أو ما إلى ذلك.	 " },
  { id: "	231	", q: "	ليس لى أعداء يريدون ضررى.	 " },
  { id: "	232	", q: "	أميل إلى الحذر من الناس الذين يظهرون صداقة أكثر مما توقعت.	 " },
  { id: "	233	", q: "	عندى أفكار غريبة غير عادية.	 " },
  { id: "	234	", q: "	أسمع أصواتاً غريبة عندما أكون بمفردى.	 " },
  { id: "	235	", q: "	أقلق وأضطرب عندما أضطر إلى القيام برحلة صغيرة بعيداً عن المنزل.	 " },
  { id: "	236	", q: "	حدث أن خفت من أمور أو من أشخاص كنت أعلم أنهم لا يستطيعون أن يضروني.	 " },
  { id: "	237	", q: "	لا أشعر بخوف من الدخول بمفردي في حجرة بها أناس يتحدثون.	 " },
  { id: "	238	", q: "	أخاف أن أستخدم سكيناً أو أي شي حاد أو مدبب.	 " },
  { id: "	239	", q: "	أجد متعة أحياناً في إيذاء الأشخاص الذين أحبهم.	 " },
  { id: "	240	", q: "	أجد صعوبة في تركيز تفكيري.	 " },
  { id: "	241	", q: "	حدث أن امتنعت عدة مرات عن القيام بعمل ما لاعتقادي بضعف قدرتي على القيام به.	 " },
  { id: "	242	", q: "	تطرأ على فكرى أحياناً كلمات بذيئة أو مخجلة لا أستطيع التخلص منها.	 " },
  { id: "	243	", q: "	تستولى على أحياناً فكرة تافهة وتظل تضايقني عدة أيام.	 " },
  { id: "	244	", q: "	يحدث في كل يوم تقريباً ما يسبب لي الفزع.	 " },
  { id: "	245	", q: "	أميل إلى أن آخذ الأشياء مأخذ الجد.	 " },
  { id: "	246	", q: "	إننى أكثر حساسية من معظم الناس.	 " },
  { id: "	247	", q: "	حدث أحياناً أن استمتعت بايذاء بعض أحبائي لي.	 " },
  { id: "	248	", q: "	يقول الناس عنى أشياء مهيئة ووضيعة.	 " },
  { id: "	249	", q: "	لا أشعر بارتياح داخل المنزل.	 " },
  { id: "	250	", q: "	أشعر في معظم الأحيان بالوحدة حتى ولو كنت مع آخرين.	 " },
  { id: "	251	", q: "	لو أعطيت الفرصة لاستطعت أن أقوم بأعمال جليلة الفائدة للعالم.	 " },
  { id: "	252	", q: "	عند سماعي بنجاح شخص أعرفه معرفة جيدة أشعر كما لو كان نجاحه فشلاً بالنسبة لي.	 " },
  { id: "	253	", q: "	لو أعطيت الفرصة لأصبحت زعيماً نافعاً للناس.	 " },
  { id: "	254	", q: "	لست أكثر حساسية من المعتاد.	 " },
  { id: "	255	", q: "	يخجلني سماع الحكايات القبيحة.	 " },
  { id: "	256	", q: "	يطلب الناس عادة أن تحترم حقوقهم بدرجة تفوق استعدادهم لاحترام حقوق الآخرين.	 " },
  { id: "	257	", q: "	أحاول أن أتذكر القصص المسلية لأروبها للآخرين.	 " },
  { id: "	258	", q: "	أحب أن أقوم بمراهنات على مبالغ صغيرة.	 " },
  { id: "	259	", q: "	في الحفلات أميل إلى أن اكون بمفردي أو مع شخص واحد فقط بدلاً من أن انضم إلى مجموعة كبيرة.	 " },
  { id: "	260	", q: "	استمتع بالاجتماعات لمجرد رغبتي في الوجود مع الناس.	 " },
  { id: "	261	", q: "	استمتع بهياج الجماهير.	 " },
  { id: "	262	", q: "	يبدو أن قلقى يزول عندما أوجد مع جماعة  من الأصدقاء المرحين.	 " },
  { id: "	263	", q: "	اننى لا أشارك الجماعة التي أنتمي اليها في الكثير من الكلام أو الحديث عن الناس.	 " },
  { id: "	264	", q: "	يخيب الناس عادة ظني أو رجاني.	 " },
  { id: "	265	", q: "	لا أجد صعوبة في بدء التبول أو في ضبطه.	 " },
  { id: "	266	", q: "	كثيراً ما وجدت الناس يغارون من أفكارى الجيدة لمجرد أنهم لم يكونوا أسبق منى في الوصول اليها.	 " },
  { id: "	267	", q: "	أتحاشى قدر إمكاني أن أوجد وسط الجماهير.	 " },
  { id: "	268	", q: "	لا يضيرني أن أقابل الغرباء.	 " },
  { id: "	269	", q: "	أميل إلى ترك ما أريد أن أفعله، إذا رأى الآخرون أنه أمر لا يستحق التنفيذ.	 " },
  { id: "	270	", q: "	عندما أركب في القطارات أو في السيارات العامة فانني أتحدث عادة إلى الركاب.	 " },
  { id: "	271	", q: "	أشعر برغبة في التسليم بسرعة عندما تسوء الأمور.	 " },
  { id: "	272	", q: "	أحب الذهاب إلى حفلات الرقص.	 " },
  { id: "	273	", q: "	مرت على فترات كنت أشعر فيها بنشاط زائد لدرجة أنني لم أكن أشعر بحاجة إلى النوم لمدة أيام.	 " },
  { id: "	274	", q: "	لا أشعر بحرج عندما أكون في مجتمع ويطلب إلى أن أبدأ مناقشة أو أبدى رأيي في شئ أعرفه معرفة جيده.	 " },
  { id: "	275	", q: "	أحب الاجتماعات والحفلات.	 " },
  { id: "	276	", q: "	لا أستطيع أن أواجه أزمة أو صعوبة.	 " },
  { id: "	277	", q: "	كثيراً ما قلت لنفسى ياليتني عدت طفلاً مرة أخرى.	 " },
  { id: "	278	", q: "	أتذكر أنني كنت أتصنع المرض لأنقذ نفسى من بعض المآزق.	 " },
]
export default data;