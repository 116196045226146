const data = [
  {
    name: "الهتسيريا",
    description:
      "هي مرض نفسي عصبي تظهر فيه اضطرابات انفعالية مع خلل في أعصاب الحس والحركة وهو اضطراب تحتوي فيه الانفعالات المزمنة على ظهور أعراض جسمية ليس لها أي أساس عضوي",
    symptoms:
      "الأعراض الحسية : العمى الهستيري، الصمم الهستيري، فقدان حاسة الشم، فقدان حاسـة الذوق، فقـدان الحساسية الجلدية في عضو أو في عدة أعضاء . الأعراض العقلية : اضطراب الوعي، الطفليه الهستيرية كالسلوك كالأطفال أو التكلم كالأطفال . الأعراض العامة : المرض عند بداية المدرسة أو عند الامتحانات، ردود الفعل السلوكية المبالغ فيها للمواقف المختلفة",
  },
  {
    name: "الفوبيا",
    description:
      "هو خوف غير عقلاني في شدته أو ماهيته حيث يرتبط هذا الخوف بجسم، أو فعالية، أو حالة معينة، ويُسبب التعرض لمسبب الخوف القلق فورًا",
    symptoms:
      "عدم الثبات، والدوخة، والدوار غثيان تعرق زيادة معدل ضربات القلب أو الخفقان ضيق في التنفس الرجفان أو الاهتزاز اضطراب المعدة",
  },
  {
    name: "النرجسيه",
    description:
      "هو عبارة عن حالة مرَضية تؤثر على الصحة العقلية للمريض الذي ينتابه حينها شعور مبالغ فيه بأهميته. ويحتاج إلى الاهتمام والإطراء من الآخرين بشكل زائد",
    symptoms:
      "العظمة واألهمية الذاتية تخيلات الكمال والتفوق الشعور بالتميز والتفرد شعور قوي باالستحقاق ميل الستغالل الاخرين الحاجة إلى الثناء واالهتمام عدم التعاطف",
  },
  {
    name: "الهوس",
    description:
      "هي حالة من الانفعال أو ارتفاع المزاج بشكل غير طبيعي، والإثارة، أو مستويات الطاقة",
    symptoms: "اضطراب الفكر هوس العظمة لإفراط في التفسير",
  },
  {
    name: "الفصام",
    description:
      "هو اضطراب حاد في الدماغ يُشوه طريقة الشخص في التفكير، والتصرف، والتعبير عن مشاعره، والنظر إلى الواقع، ورؤية الوقائع والعلاقات المتبادلة بينه وبين المحيطين به",
    symptoms:
      "الهلوسه الاوهام  بطء في الحركة عدم القدرة على اتخاذ القرارات الانشغال الزائد بكتابة عديمة المعنى  انعدام الإحساس أو التعبير عن المشاعر",
  },
  {
    name: "انطواء اجتماعي",
    description:
      "هو اضطراب عقلي لا يُظهر فيه الشخص أبدًا أي اعتبار للصواب والخطأ، ويتجاهل حقوق الآخرين ومشاعرهم. يميل الأشخاص المصابون باضطراب الشخصية المعادية للمجتمع إلى التلاعب بالآخرين أو معاملتهم بلا مبالاة قاسية. لا يُظهرون أي إحساس بالذنب أو الندم على سلوكهم",
    symptoms:
      "عدم الاكتراث للصواب والخطأ  الإصرار على الكذب أو الخداع لاستغلال الآخرين القسوة والسُّخرية وعدم احترام الآخرين استخدام الذكاء أو المَكْر لغِشِّ الآخرين لتحقيق مكاسب شخصية أو لجلب سعادة شخصية الغطرسة والشعور بالاستعلاء والتشبُّث الشديد بالرأي المشاكل المُتكرِّرة مع القانون بما في ذلك السُّلوك الإجرامي",
  },
  {
    name: "البارانويا",
    description:
      "مرض البارانويا أو ما يعرف بجنون الارتياب هو نمط تفكير ينجم عنه الشعور الغير منطقي بفقد الثقة بالناس، والريبة منهم والاعتقاد بوجود تهديد ما، مثل: الإحساس بأن هناك أشخاص يراقبونك، أو يحاولون إلحاق الأذى بك بالرغم من عدم وجود دليل على ذلك",
    symptoms:
      "الارتياب والشك من أفعال وتحركات الآخرين عدم الوثوق بالآخرين كون الشخص دفاعي وعدائي سهولة الشعور بالإهانة اعتقاد الشخص بأنه دائمًا على صواب ومواجهة صعوبة في الاسترخاء عدم القدرة على القبول بحلول وسطية، أو المسامحة، أو قبول النقد",
  },
  {
    name: "التوهم",
    description:
      "هي حالة صحية يقصد فيها الخوف المستمر وغير المنطقي والمتكرر على الحالة الصحية للشخص نفسه، وتعتمد بشكل أساسي على تخيل الشخص بأنه مصاب بأعراض جسدية للأمراض المختلفة، أو على خلل في فهم الوضع الطبيعي للجسم، وتضخيم الحالات البسيطة واعتبارها أمراض خطيرة",
    symptoms:
      "ضعف القدرة على فهم الأحاسيس الجسدية والمرضية وتحليلها الشكوى من اضطرابات جسمية خاصة في المعدة والأمعاء أو أي جزء آخر من أجزاء الجسم الشعور بالنقص مما يعوق الاتصال الاجتماعي ويؤدي إلى الانعزال والانسحاب",
  },
];

export default data;
