import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
// import Fade from "react-reveal/Fade";

const Validation = ({setOpenModel, setName, name, notify}) => {
  const navigate=useNavigate()
  const [inputValue, setInputValue] = useState("");
  const [nationalId, setNationalId] = useState("");

  const handleInputValue = (e) => {
    const value = e.target.value;
    if (value.length <= 14) {
      setInputValue(value)
    }
    setNationalId(value);
  }

  const CalculateAge = () => {
    const birthDate = nationalId.slice(1, 7);
    const year = parseInt(birthDate.slice(0, 2));
    const month = parseInt(birthDate.slice(2, 4));
    const day = parseInt(birthDate.slice(4, 6));
    const birthYear = year < 30 ? 2000 + year : 1900 + year;
    const birthDateObj = new Date(birthYear, month - 1, day);
    const today = new Date();
    const ageInMs = today - birthDateObj;
    const ageInYears = ageInMs / 1000 / 60 / 60 / 24 / 365;

    if (name === "") {
      notify("من فضلك ادخل اسمك", "Error")
    } else if (nationalId.length < 14) {
      notify("من فضلك ادخل الرقم القومي مكون من 14 رقم", "Error")
    } else if ( ageInYears > 60 || ageInYears < 18 ) {
      navigate("/notValid");
    } else {
      navigate("/Question");
      setOpenModel(true);
    }
  }

  
  return (
    <div className="form-input">
      {/* <Fade left> */}
        <input
          type="text"
          placeholder="ادخل الأسم.."
          className="px-5 py-2 input"
          onChange={(e) => setName(e.target.value)}
        />
        <br />
        <input
          type="text"
          placeholder="ادخل الرقم القومي.."
          className="px-5 py-2 input"
          onChange={handleInputValue}
          value={inputValue}
        />
        <br />
          <button className="btn btn-primary my-2 px-5 btn-style" onClick={CalculateAge}>
            ابدأ الاختبار
          </button>
      {/* </Fade> */}
    
    </div>
  );
};

export default Validation;
