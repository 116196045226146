import React from "react";
import Accordion from "react-bootstrap/Accordion";
import data from "../DataCharacters"

const Characters = () => {
  return (
    <div className="parent-characters mt-5">
      {data.length ? (data.map(item => {
        return (
        <Accordion className="mt-3 mb-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className='fw-bold'>
              {item.name}
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <div>
                <h5 className="fw-bold">- الوصف :</h5>
                <div className="description">
                  {item.description}
                </div>
              </div>
              <div>
                <h5 className="fw-bold">- الأعراض :</h5>
                <div className="symptoms">
                  {item.symptoms}
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
        )
      })) : "لا يوجد بيانات"}
    </div>
  );
};

export default Characters;
