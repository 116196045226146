import NavBar from "./components/NavBar";
import Landing from "./components/Landing";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Validation from "./components/Validation";
import Model from "./components/model-pop-up";
import { useEffect, useState } from "react";
import Question from "./components/Question";
import FinalResults from "./components/Final-Results";
import NotValid from "./components/Not-Valid";
import {answer} from "./Answer";
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Characters from "./components/Characters"




function App() {

  const notify = (message, type) => {
    if (type === "Error") {
      toast.error(message)
    }
    if (type === "Success") {
      toast.success(message)
    }
  }

  const [ans, setAnswer] = useState(null);

  const [openModel, setOpenModel] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showQuestion, setShowQuestion] = useState(true);
  const [name,setName] = useState("");
  
  useEffect(() => {
    if (ans !== null) {
      answer.push(ans)
    }
  })

  return (
    <div className="font" style={{minHeight:"670px"}}>
      <BrowserRouter>
      <NavBar/>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/Validation" element={<Validation setOpenModel={setOpenModel} setName={setName} notify={notify} name={name}/>}/>
          <Route path="/Question" element={showQuestion ? (<Question setShowQuestion={setShowQuestion} setAnswer={setAnswer} setCurrentQuestion={setCurrentQuestion} currentQuestion={currentQuestion} name={name}/>) : null} />
          <Route path="/notValid" element={<NotValid/>} />
          <Route path="/FinalResults" element={<FinalResults/>} />
          <Route path="/Characters" element={<Characters/>}/>
        </Routes>
      </BrowserRouter>
      <Model openModel={openModel} setOpenModel={setOpenModel} name={name}/>
      <ToastContainer/>
      <Footer/>
    </div>
  );
}

export default App;
