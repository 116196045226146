import React from 'react'
import { Col, Row } from 'react-bootstrap';
import data from "../DataQustion";
import { useNavigate } from 'react-router-dom';


const Question = ({ currentQuestion,buttonValue,buttonValue2 , setCurrentQuestion, setAnswer, setShowQuestion}) => {

  const navigate=useNavigate()

  const handleYesClick = () => {
    setAnswer(1)
    if (currentQuestion + 1 < data.length) {
      setCurrentQuestion(currentQuestion + 1)
    } else {
      setShowQuestion(false)
      navigate("/FinalResults")
    }
  }

  const handleNoClick = () => {
    setAnswer(0)
    if (currentQuestion + 1 < data.length) {
      setCurrentQuestion(currentQuestion + 1)
    } else {
      setShowQuestion(false)
      navigate("/FinalResults")
    }
  }

  return (
    <div className='Question-component'>
      <Row>
        <Col sm="12" className='numQ'>
          <h6>السؤال {currentQuestion + 1 } من {data.length}</h6>
        </Col>
        <Col sm="12" className='text-center'>
          <h3>{data[currentQuestion].q}</h3>
        </Col>
        <Col sm="12" className='d-flex justify-content-evenly pt-5'>
            <button className="btn btn-primary my-2 px-5 btn-style" onClick={handleYesClick} value={buttonValue}>
              نعم
            </button>
            <button className="btn btn-primary my-2 px-5 btn-style" onClick={handleNoClick} value={buttonValue2}>
              لا
            </button>
        </Col>
      </Row>
    </div>
  )
}

export default Question;