import React from "react";
import { Container } from "react-bootstrap";
import logo from "../images/logo4.png";
import { Link } from "react-router-dom";

const NavBar = () => {

  return (
    <div className="py-2 nav">
      <Container>
        <div className="parent-nav">
          <div>
            <a href="/">
              <img src={logo} alt="logo" className="logo" />
            </a>
          </div>
          <div className="Characters fs-5">
            {/* <a href="/Characters"> */}
            <Link to="/Characters">
              انواع الامراض
            </Link>
            {/* </a> */}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NavBar;
