import React from "react";

const Footer = () => {

  const dateNow = new Date();

  return (
    <div dir="ltr" className="footer">
      <div className="icons">
        <p style={{display:"inline-block"}}>Find Us On Social Networks</p>
        <div style={{display:"inline-block"}} className="twitter"><i class="fa-brands fa-twitter"></i></div>
        <div style={{display:"inline-block"}} className="facebook"><i class="fa-brands fa-facebook"></i></div>
        <div style={{display:"inline-block"}} className="instagram"><i class="fa-brands fa-instagram"></i></div>
      </div>
      <div className="copyright">
        <i class="fa-regular fa-copyright"></i> {dateNow.getFullYear()}{" "}
        {/* <span>Abdallah Yasser</span> */} All Right Reserved 
      </div>
    </div>
  );
};

export default Footer;
