import React from "react";

const Model = ({ openModel,setOpenModel,name}) => {

  return openModel ? (
      <div className="model-container">
        <h5 className="fw-bold">اهلاّ بك {name}</h5>
        <h3 className="fw-bold">تعليمات</h3>
        <p className="fs-5">-اقرأ كل عبارة وقرر ما اذا كانت تنطبق عليك أم لا.</p>
        <p className="fs-5">-قد يستغرق الاختبار من 60 الي 90 دقيقة.</p>
        <p className="fs-5">-جاوب بصدق (حتى إذا لم تعجبك الإجابة).</p>
        {/* <p className="fs-5">-تذكر أن المطلوب منك هو أن تعبر عن فكرتك انت عن نفسك.</p> */}
        <button className="btn btn-primary my-2 px-5 btn-style" onClick={() => setOpenModel(false)}>موافق</button>
      </div>
  ) : null;
};

export default Model;



