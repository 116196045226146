import React from "react";
import { Link } from "react-router-dom";

const NotValid = () => {
  return (
    <div className="form-input text-center">
      <h3>للأسف سنك غير مناسب لخوض الاختبار</h3>
      <Link to="/">
        <button className="btn btn-primary my-2 px-5 btn-style">الصفحة الرئيسية</button>
      </Link>
    </div>
  );
};

export default NotValid;
